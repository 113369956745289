import React, { Suspense } from 'react';
import cs from 'classnames';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import CheckToggleIcon from './CheckToggleIcon.svg';
import { Theme, useThemeContext } from '../../context/ThemeContext/ThemeContext';
import s from './ThemePickerButton.scss';
import { reportActionInSidebar } from '../../utils/bi';
import { ThemeId } from '../../context/ThemeContext/constants/themeId';
import { getFullFontFamily } from '../../context/ThemeContext/fonts/fontUtils';
import { themePickerButtonDataHooks } from './ThemePickerButton.dataHooks';

interface BaseThemePickerButtonProps extends WithTranslation {
  dataHook?: string;
}

interface ThemePickerButtonProps extends BaseThemePickerButtonProps {
  themeId: ThemeId;
  isDefaultTheme?: never;
}

interface DefaultThemePickerButtonProps extends BaseThemePickerButtonProps {
  themeId?: never;
  isDefaultTheme: true;
}

const pickMainColorsFromTheme = (colors: Theme['colors'], fonts: Theme['fonts']) => {
  const { color_11, color_15, color_18, color_23, color_28, color_33 } = colors;
  return {
    backgroundColor: color_11,
    fontColor: color_15,
    restColors: [color_18, color_23, color_28, color_33],
    font: fonts.font_0,
  };
};

const themesToFontIconsImportsMap = {
  [ThemeId.BOHEMIAN_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Bohemian.svg'),
  [ThemeId.CONTEMPORARY_THEME]: () =>
    import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Contemporary.svg'),
  [ThemeId.CREAMY_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Creamy.svg'),
  [ThemeId.DYNAMIC_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Dynamic.svg'),
  [ThemeId.ENERGETIC_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Energetic.svg'),
  [ThemeId.ECLECTIC_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Eclectic.svg'),
  [ThemeId.FASHIONABLE_THEME]: () =>
    import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Fashionable.svg'),
  [ThemeId.LAID_BACK_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Laidback.svg'),
  [ThemeId.MATURE_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Mature.svg'),
  [ThemeId.NATURAL_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Natural.svg'),
  [ThemeId.NOSTALGIC_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Nostalgic.svg'),
  [ThemeId.PLACID_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Placid.svg'),
  [ThemeId.RETRO_VIBRANT_THEME]: () =>
    import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/RetroVibrant.svg'),
  [ThemeId.SAVVY_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Savvy.svg'),
  [ThemeId.TANGY_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Tangy.svg'),
  [ThemeId.TECHNICAL_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Technical.svg'),
  [ThemeId.VIVID_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Vivid.svg'),
  [ThemeId.WARM_THEME]: () => import(/* webpackChunkName: "themeFontIcons" */ './themeFontIcons/Warm.svg'),
};

const ThemePickerButtonCmp: React.FC<ThemePickerButtonProps | DefaultThemePickerButtonProps> = ({
  dataHook,
  themeId,
  isDefaultTheme,
  t,
}) => {
  const { setTheme, themeList, initialTheme, currentThemeId } = useThemeContext();
  const theme = themeList.find(({ id }) => id === themeId) ?? initialTheme;
  const isActive = isDefaultTheme ? currentThemeId === null : themeId === currentThemeId;

  const handleChange = () => {
    setTheme(themeId ?? null);

    reportActionInSidebar({ actionName: 'pick_site_theme', actionValue: themeId ?? 'Default theme' });
  };

  const { backgroundColor, fontColor, restColors, font } = pickMainColorsFromTheme(theme.colors, theme.fonts);
  const fontFamily = getFullFontFamily(font);
  const ThemeFontIcon = React.useMemo(
    () => (isDefaultTheme ? 'div' : React.lazy(() => themesToFontIconsImportsMap[themeId]())),
    [isDefaultTheme, themeId],
  );
  const fallbackLabel = (
    <span
      className={s.label}
      style={{ color: fontColor, fontFamily }}
      data-hook={themePickerButtonDataHooks.themeTitle()}
    >
      {t(theme.title)}
    </span>
  );

  return (
    <div
      data-hook={dataHook}
      className={cs(s.button, {
        [s.active]: isActive,
      })}
      onClick={handleChange}
      style={{ backgroundColor }}
      role="button"
    >
      {isDefaultTheme ? (
        fallbackLabel
      ) : (
        <Suspense fallback={fallbackLabel}>
          <ThemeFontIcon
            style={{ fill: fontColor }}
            className={cs(s.fontIcon, {
              [s.contemporary]: themeId === ThemeId.CONTEMPORARY_THEME,
              [s.creamy]: themeId === ThemeId.CREAMY_THEME,
              [s.dynamic]: themeId === ThemeId.DYNAMIC_THEME,
              [s.savvy]: themeId === ThemeId.SAVVY_THEME,
              [s.tangy]: themeId === ThemeId.TANGY_THEME,
            })}
            data-hook={themePickerButtonDataHooks.fontIcon({ themeId })}
          />
        </Suspense>
      )}
      <div className={s.colorBulletList}>
        {restColors.map((color, index) => (
          <div
            className={s.colorBullet}
            style={{ backgroundColor: color }}
            data-hook={themePickerButtonDataHooks.colorBullet()}
            key={`${color}-${index}`}
          ></div>
        ))}
      </div>
      {isActive && <CheckToggleIcon className={s.activeIcon} data-hook={themePickerButtonDataHooks.activeIcon()} />}
    </div>
  );
};

export const ThemePickerButton = withTranslation()(ThemePickerButtonCmp);
