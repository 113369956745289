import React, { useContext } from 'react';
import { HideSidebar } from '@wix/wix-ui-icons-common';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';
import s from './SidebarHeader.scss';
import { UIContext } from '../../context/UIContext/UIContext';
import { useExperimentContext } from '../../context/ExperimentContext/ExperimentContext';
import { reportActionInSidebar } from '../../utils/bi';

interface SidebarHeaderProps extends WithTranslation {
  dataHook?: string;
}

const SidebarHeaderCmp: React.FC<SidebarHeaderProps> = ({ dataHook, t }) => {
  const { setIsSidebarVisible } = useContext(UIContext);
  const { activeExperiments } = useExperimentContext();
  const isCollapsibleSidebar = activeExperiments.includes('TemplateViewerCollapsibleSidebar');
  const hideSidebar = () => {
    setIsSidebarVisible(false);
    reportActionInSidebar({
      actionName: 'toggle_theme_sidebar',
      actionValue: 'close',
    });
  };

  return (
    <div className={s.header} data-hook={dataHook}>
      <div className={s.title}>{t('template.viewer.sidebar.title')}</div>
      <div className={s.description}>{t('template.viewer.sidebar.description')}</div>
      {isCollapsibleSidebar && (
        <div role="button" onClick={hideSidebar} className={s.hideSidebarButton}>
          <HideSidebar className={s.hideSidebarIcon} />
          <span className={s.hideSidebarTooltip}>Hide sidebar</span>
        </div>
      )}
    </div>
  );
};

export const SidebarHeader = withTranslation()(SidebarHeaderCmp);
