import React from 'react';
import CustomizeIcon from './CustomizeIcon.svg';
import s from './SidebarToggleButton.scss';
import { useUIContext } from '../../context/UIContext/UIContext';
import { reportActionInSidebar } from '../../utils/bi';

export const SidebarToggleButton = () => {
  const { setIsSidebarVisible } = useUIContext();
  const showSidebar = () => {
    setIsSidebarVisible(true);
    reportActionInSidebar({
      actionName: 'toggle_theme_sidebar',
      actionValue: 'open',
    });
  };

  return (
    <div className={s.button} role="button" onClick={showSidebar}>
      <CustomizeIcon className={s.icon} />
      <span className={s.label}>Customize</span>
      <span className={s.tooltip}>Open sidebar</span>
    </div>
  );
};
