import React, { useContext } from 'react';

export interface StoreTemplate {
  url: string;
  price: string;
  description: string;
  title: string;
  docUrl: string;
  editorUrl: string;
  goodFor: string;
  siteId: string;
  image: string;
  id: string;
  lng: string;
  metaSiteId: string;
  editorSessionId: string;
  isResponsive: boolean;
  isAdiTemplate?: boolean;
}

interface TemplateContextShape {
  template: StoreTemplate;
}

export const defaultTemplateContextValue: TemplateContextShape = {
  template: {
    url: '',
    price: '',
    description: '',
    title: '',
    docUrl: '',
    editorUrl: '',
    goodFor: '',
    siteId: '',
    image: '',
    id: '',
    lng: '',
    metaSiteId: '',
    editorSessionId: '',
    isResponsive: false,
    isAdiTemplate: false,
  },
};

const TemplateContext = React.createContext<TemplateContextShape>(defaultTemplateContextValue);
TemplateContext.displayName = 'TemplateContext';

const useTemplateContext = () => useContext(TemplateContext);

export { TemplateContext, TemplateContextShape, useTemplateContext };
