import { DefaultTheme, Theme } from '../ThemeContext';
import { fontsMetadata } from './fontsMetadata';

const GOOGLE_FONT_SERVICE_URL = '//fonts.googleapis.com/css?family=';

export const getFontCSSFromFontString = (fontVal: string) => {
  let font = fontVal;
  if (font.includes('#')) {
    font = font.slice(0, font.indexOf('#'));
  }
  font = font.replace(/\{color_\d+\}/, '');
  const fullFontFamily = getFullFontFamily(font);

  const childFont = replaceFontFamily(font, fullFontFamily);
  return childFont.trim();
};

export const getThemeFontFamilies = (theme: Theme | DefaultTheme) => {
  return [...new Set(Object.values(theme.fonts).map((font) => getFontFamily(font).toLowerCase()))];
};

export const getFullFontFamily = (fontString: string) => {
  let fullFontFamily = getFontFamily(fontString);
  const fallback = getFontFallback(fullFontFamily);
  if (fallback) {
    fullFontFamily = `${fullFontFamily},${fallback}`;
  }

  return fullFontFamily.replace(/\+/g, ' ');
};

export const getFontsUrlWithParams = (fontFamilies: string[], characterSets?: string[]) => {
  const query = getFontsQuery(fontFamilies, characterSets);
  if (query) {
    return GOOGLE_FONT_SERVICE_URL + query;
  }
  return '';
};

export const getCustomFontDeclarations = (fontFamilies: string[]): string => {
  return fontFamilies.map((fontFamily) => fontsMetadata[fontFamily]?.fontFaceDeclaration ?? '').join('\n');
};

function replaceFontFamily(font: string, fullFontFamily: string) {
  const splittedFont = font.split(' ');
  return [...splittedFont.slice(0, 4), fullFontFamily, ...splittedFont.slice(5)].join(' ');
}

function getFontsQuery(fontsFamiliesArray: string[], characterSets?: string[]) {
  let fontQuery = '';
  fontsFamiliesArray.forEach((fontFamily) => {
    const font = fontsMetadata[fontFamily];
    if (font && font.cdnName) {
      fontQuery += `${font.cdnName}:n,b,i,bi|`;
    }
  });
  if (fontQuery === '') {
    return null;
  }
  if (characterSets) {
    fontQuery += `&subset=${characterSets.join(',')}`;
  }
  return fontQuery;
}

export function getFontFamily(fontStr: string) {
  return fontStr.split(' ')[4]?.replace(/\+/g, ' ').trim();
}

function getFontFallback(fontFamily: string) {
  const cleanFontName = fontFamily.toLowerCase();
  const fontMeta = fontsMetadata[cleanFontName];
  if (fontMeta) {
    let fallback = fontMeta.fallbacks;
    if (fallback) {
      fallback += ',';
    }
    fallback += fontMeta.genericFamily;
    return fallback;
  }
  return '';
}

export function replaceFontFamilyAndColor(fontSizeSource: string, fontFamilySoruce: string) {
  const fontWeightsAndSize = fontSizeSource.split(' ').slice(0, 4);
  const fontFamilyAndColor = fontFamilySoruce.split(' ').slice(4);
  return [...fontWeightsAndSize, ...fontFamilyAndColor].join(' ');
}
