import React, { FC, PropsWithChildren, useState } from 'react';
import { reportDesktopView, reportMobileView, reportTabletView } from '../../utils/bi';
import { VIEW_MODE } from '../../utils/helpers';
import { UIContext, UIContextShape } from './UIContext';
import { useExperimentContext } from '../ExperimentContext/ExperimentContext';

interface UIContextProviderProps {
  initialState: Pick<UIContextShape, 'viewMode' | 'isEditButtonHidden' | 'isInfoShown'>;
}

export const UIContextProvider: FC<PropsWithChildren<UIContextProviderProps>> = ({ initialState, children }) => {
  const [viewMode, setViewMode] = useState(initialState.viewMode);
  const [isInfoShown, setIsInfoShown] = useState(initialState.isInfoShown);
  const { activeExperiments } = useExperimentContext();
  const [isSidebarVisible, setIsSidebarVisible] = useState(activeExperiments.includes('NewViewerPageCapabilities'));

  const toggleViewMode = (newValue: VIEW_MODE) => {
    if (viewMode === VIEW_MODE.DESKTOP) {
      reportDesktopView();
    }
    if (viewMode === VIEW_MODE.TABLET) {
      reportTabletView();
    }
    if (viewMode === VIEW_MODE.MOBILE) {
      reportMobileView();
    }

    setViewMode(newValue);
  };

  const contextValue: UIContextShape = {
    viewMode,
    toggleViewMode,
    isEditButtonHidden: initialState.isEditButtonHidden,
    isInfoShown,
    setIsInfoShown,
    isSidebarVisible,
    setIsSidebarVisible,
  };

  return <UIContext.Provider value={contextValue}>{children}</UIContext.Provider>;
};
