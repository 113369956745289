import React, { useContext } from 'react';
import { ThemeId } from './constants/themeId';

export interface Theme {
  id: ThemeId;
  title: string;
  colors: { [color: string]: string };
  fonts: { [font: string]: string };
}

export interface DefaultTheme extends Omit<Theme, 'id'> {
  id: null;
}

export const defaultTheme: DefaultTheme = {
  id: null,
  title: 'template.viewer.theme.default',
  colors: {
    color_11: '#ffffff',
    color_15: '#000000',
    color_18: '#e2e2e2',
    color_23: '#e2e2e2',
    color_28: '#e2e2e2',
    color_33: '#e2e2e2',
  },
  fonts: {
    font_0: 'normal normal normal 52px/1.4em "Madefor","Helvetica Neue",Helvetica,Arial,sans-serif',
  },
};

export interface ThemeContextShape {
  bindTheme: (iframe: HTMLIFrameElement | null) => () => void;
  setIframeBindings: (iframe: HTMLIFrameElement | null) => void;
  initialTheme: Theme | DefaultTheme;
  currentThemeId: ThemeId | null;
  setTheme: (themeId: ThemeId | null) => void;
  themeList: Theme[];
}

export const defaultThemeContextValue = {
  bindTheme: () => () => {},
  setIframeBindings: () => {},
  initialTheme: defaultTheme,
  currentThemeId: null,
  setTheme: () => {},
  themeList: [],
};

export const ThemeContext = React.createContext<ThemeContextShape>(defaultThemeContextValue);
ThemeContext.displayName = 'ThemeContext';

export const useThemeContext = () => useContext(ThemeContext);
