import { ThemeId } from './themeId';

export const FONTS: Record<string, { [key: string]: string }> = {
  [ThemeId.DYNAMIC_THEME]: {
    font_0: 'normal normal bold 85px/1.4em space+grotesk {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em space+grotesk {color_15}',
    font_3: 'normal normal normal 28px/1.4em space+grotesk {color_15}',
    font_4: 'normal normal bold 28px/1.4em space+grotesk {color_15}',
    font_5: 'normal normal normal 24px/1.4em space+grotesk {color_15}',
    font_6: 'normal normal normal 19px/1.4em space+grotesk {color_15}',
    font_7: 'normal normal normal 19px/1.4em poppins-extralight {color_15}',
    font_8: 'normal normal normal 16px/1.4em poppins-extralight {color_15}',
    font_9: 'normal normal normal 14px/1.4em poppins-extralight {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.SAVVY_THEME]: {
    font_0: 'normal normal bold 85px/1.4em roboto-bold {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em roboto-bold {color_15}',
    font_3: 'normal normal normal 28px/1.4em roboto-bold {color_15}',
    font_4: 'normal normal bold 28px/1.4em roboto-bold {color_15}',
    font_5: 'normal normal normal 24px/1.4em roboto-bold {color_15}',
    font_6: 'normal normal normal 19px/1.4em roboto-bold {color_15}',
    font_7: 'normal normal normal 19px/1.4em montserrat {color_15}',
    font_8: 'normal normal normal 16px/1.4em montserrat {color_15}',
    font_9: 'normal normal normal 14px/1.4em montserrat {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.VIVID_THEME]: {
    font_0: 'normal normal bold 85px/1.4em syne {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em syne {color_15}',
    font_3: 'normal normal normal 28px/1.4em syne {color_15}',
    font_4: 'normal normal bold 28px/1.4em syne {color_15}',
    font_5: 'normal normal normal 24px/1.4em syne {color_15}',
    font_6: 'normal normal normal 19px/1.4em syne {color_15}',
    font_7: 'normal normal normal 19px/1.4em questrial {color_15}',
    font_8: 'normal normal normal 16px/1.4em questrial {color_15}',
    font_9: 'normal normal normal 14px/1.4em questrial {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.MATURE_THEME]: {
    font_0: 'normal normal bold 85px/1.4em quicksand {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em quicksand {color_15}',
    font_3: 'normal normal normal 28px/1.4em quicksand {color_15}',
    font_4: 'normal normal bold 28px/1.4em quicksand {color_15}',
    font_5: 'normal normal normal 24px/1.4em quicksand {color_15}',
    font_6: 'normal normal normal 19px/1.4em quicksand {color_15}',
    font_7: 'normal normal normal 19px/1.4em quicksand {color_15}',
    font_8: 'normal normal normal 16px/1.4em quicksand {color_15}',
    font_9: 'normal normal normal 14px/1.4em quicksand {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.CONTEMPORARY_THEME]: {
    font_0: 'normal normal bold 85px/1.4em fahkwang {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em fahkwang {color_15}',
    font_3: 'normal normal normal 28px/1.4em fahkwang {color_15}',
    font_4: 'normal normal bold 28px/1.4em fahkwang {color_15}',
    font_5: 'normal normal normal 24px/1.4em fahkwang {color_15}',
    font_6: 'normal normal normal 19px/1.4em fahkwang {color_15}',
    font_7: 'normal normal normal 19px/1.4em helvetica+neue {color_15}',
    font_8: 'normal normal normal 16px/1.4em helvetica+neue {color_15}',
    font_9: 'normal normal normal 14px/1.4em helvetica+neue {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.LAID_BACK_THEME]: {
    font_0: 'normal normal bold 85px/1.4em fraunces {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em fraunces {color_15}',
    font_3: 'normal normal normal 28px/1.4em fraunces {color_15}',
    font_4: 'normal normal bold 28px/1.4em fraunces {color_15}',
    font_5: 'normal normal normal 24px/1.4em fraunces {color_15}',
    font_6: 'normal normal normal 19px/1.4em fraunces {color_15}',
    font_7: 'normal normal normal 19px/1.4em lato-light {color_15}',
    font_8: 'normal normal normal 16px/1.4em lato-light {color_15}',
    font_9: 'normal normal normal 14px/1.4em lato-light {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.BOHEMIAN_THEME]: {
    font_0: 'normal normal bold 85px/1.4em caudex {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em caudex {color_15}',
    font_3: 'normal normal normal 28px/1.4em caudex {color_15}',
    font_4: 'normal normal bold 28px/1.4em caudex {color_15}',
    font_5: 'normal normal normal 24px/1.4em caudex {color_15}',
    font_6: 'normal normal normal 19px/1.4em caudex {color_15}',
    font_7: 'normal normal normal 19px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_8: 'normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_9: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.FASHIONABLE_THEME]: {
    font_0: 'normal normal bold 85px/1.4em futura-lt-w01-book {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em futura-lt-w01-book {color_15}',
    font_3: 'normal normal normal 28px/1.4em futura-lt-w01-book {color_15}',
    font_4: 'normal normal bold 28px/1.4em futura-lt-w01-book {color_15}',
    font_5: 'normal normal normal 24px/1.4em futura-lt-w01-book {color_15}',
    font_6: 'normal normal normal 19px/1.4em futura-lt-w01-book {color_15}',
    font_7: 'normal normal normal 19px/1.4em cormorantgaramond-light {color_15}',
    font_8: 'normal normal normal 16px/1.4em cormorantgaramond-light {color_15}',
    font_9: 'normal normal normal 14px/1.4em cormorantgaramond-light {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.TECHNICAL_THEME]: {
    font_0: 'normal normal bold 85px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_3: 'normal normal normal 28px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_4: 'normal normal bold 28px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_5: 'normal normal normal 24px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_6: 'normal normal normal 19px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_7: 'normal normal normal 19px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_8: 'normal normal normal 16px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_9: 'normal normal normal 14px/1.4em avenir-lt-w01_85-heavy1475544 {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.ENERGETIC_THEME]: {
    font_0: 'normal normal bold 85px/1.4em syne {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em syne {color_15}',
    font_3: 'normal normal normal 28px/1.4em syne {color_15}',
    font_4: 'normal normal bold 28px/1.4em syne {color_15}',
    font_5: 'normal normal normal 24px/1.4em syne {color_15}',
    font_6: 'normal normal normal 19px/1.4em syne {color_15}',
    font_7: 'normal normal normal 19px/1.4em questrial {color_15}',
    font_8: 'normal normal normal 16px/1.4em questrial {color_15}',
    font_9: 'normal normal normal 14px/1.4em questrial {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.NOSTALGIC_THEME]: {
    font_0: 'normal normal bold 85px/1.4em forum {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em forum {color_15}',
    font_3: 'normal normal normal 28px/1.4em forum {color_15}',
    font_4: 'normal normal bold 28px/1.4em forum {color_15}',
    font_5: 'normal normal normal 24px/1.4em forum {color_15}',
    font_6: 'normal normal normal 19px/1.4em forum {color_15}',
    font_7: 'normal normal normal 19px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_8: 'normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_9: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.NATURAL_THEME]: {
    font_0: 'normal normal bold 85px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_3: 'normal normal normal 28px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_4: 'normal normal bold 28px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_5: 'normal normal normal 24px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_6: 'normal normal normal 19px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_7: 'normal normal normal 19px/1.4em questrial {color_15}',
    font_8: 'normal normal normal 16px/1.4em questrial {color_15}',
    font_9: 'normal normal normal 14px/1.4em questrial {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.RETRO_VIBRANT_THEME]: {
    font_0: 'normal normal bold 85px/1.4em space+grotesk {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em space+grotesk {color_15}',
    font_3: 'normal normal normal 28px/1.4em space+grotesk {color_15}',
    font_4: 'normal normal bold 28px/1.4em space+grotesk {color_15}',
    font_5: 'normal normal normal 24px/1.4em space+grotesk {color_15}',
    font_6: 'normal normal normal 19px/1.4em space+grotesk {color_15}',
    font_7: 'normal normal normal 19px/1.4em poppins-extralight {color_15}',
    font_8: 'normal normal normal 16px/1.4em poppins-extralight {color_15}',
    font_9: 'normal normal normal 14px/1.4em poppins-extralight {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.WARM_THEME]: {
    font_0: 'normal normal bold 85px/1.4em cormorantgaramond-semibold {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em cormorantgaramond-semibold {color_15}',
    font_3: 'normal normal normal 28px/1.4em cormorantgaramond-semibold {color_15}',
    font_4: 'normal normal bold 28px/1.4em cormorantgaramond-semibold {color_15}',
    font_5: 'normal normal normal 24px/1.4em cormorantgaramond-semibold {color_15}',
    font_6: 'normal normal normal 19px/1.4em cormorantgaramond-semibold {color_15}',
    font_7: 'normal normal normal 19px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_8: 'normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_9: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.ECLECTIC_THEME]: {
    font_0: 'normal normal bold 85px/1.4em fahkwang {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em fahkwang {color_15}',
    font_3: 'normal normal normal 28px/1.4em fahkwang {color_15}',
    font_4: 'normal normal bold 28px/1.4em fahkwang {color_15}',
    font_5: 'normal normal normal 24px/1.4em fahkwang {color_15}',
    font_6: 'normal normal normal 19px/1.4em fahkwang {color_15}',
    font_7: 'normal normal normal 19px/1.4em helvetica+neue {color_15}',
    font_8: 'normal normal normal 16px/1.4em helvetica+neue {color_15}',
    font_9: 'normal normal normal 14px/1.4em helvetica+neue {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.CREAMY_THEME]: {
    font_0: 'normal normal bold 85px/1.4em corben {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em corben {color_15}',
    font_3: 'normal normal normal 28px/1.4em corben {color_15}',
    font_4: 'normal normal bold 28px/1.4em corben {color_15}',
    font_5: 'normal normal normal 24px/1.4em corben {color_15}',
    font_6: 'normal normal normal 19px/1.4em corben {color_15}',
    font_7: 'normal normal normal 19px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_8: 'normal normal normal 16px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_9: 'normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496 {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.TANGY_THEME]: {
    font_0: 'normal normal bold 85px/1.4em oswald-medium {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em oswald-medium {color_15}',
    font_3: 'normal normal normal 28px/1.4em oswald-medium {color_15}',
    font_4: 'normal normal bold 28px/1.4em oswald-medium {color_15}',
    font_5: 'normal normal normal 24px/1.4em oswald-medium {color_15}',
    font_6: 'normal normal normal 19px/1.4em oswald-medium {color_15}',
    font_7: 'normal normal normal 19px/1.4em futura-lt-w01-book {color_15}',
    font_8: 'normal normal normal 16px/1.4em futura-lt-w01-book {color_15}',
    font_9: 'normal normal normal 14px/1.4em futura-lt-w01-book {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
  [ThemeId.PLACID_THEME]: {
    font_0: 'normal normal bold 85px/1.4em helvetica-w01-bold {color_15}',
    font_1: 'normal normal normal 16px/1.4em din-next-w01-light {color_14}',
    font_2: 'normal normal bold 40px/1.4em helvetica-w01-bold {color_15}',
    font_3: 'normal normal normal 28px/1.4em helvetica-w01-bold {color_15}',
    font_4: 'normal normal bold 28px/1.4em helvetica-w01-bold {color_15}',
    font_5: 'normal normal normal 24px/1.4em helvetica-w01-bold {color_15}',
    font_6: 'normal normal normal 19px/1.4em helvetica-w01-bold {color_15}',
    font_7: 'normal normal normal 19px/1.4em helvetica-w01-roman {color_15}',
    font_8: 'normal normal normal 16px/1.4em helvetica-w01-roman {color_15}',
    font_9: 'normal normal normal 14px/1.4em helvetica-w01-roman {color_15}',
    font_10: 'normal normal normal 12px/1.4em din-next-w01-light {color_14}',
  },
};
