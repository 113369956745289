import React from 'react';
import { ThemePickerButton } from '../ThemePickerButton/ThemePickerButton';
import { useThemeContext } from '../../context/ThemeContext/ThemeContext';
import s from './ThemePicker.scss';
import { themePickerDataHooks } from './ThemePicker.dataHooks';
import { withTranslation, WithTranslation } from '@wix/wix-i18n-config';

interface ThemePickerProps extends WithTranslation {
  dataHook?: string;
}

const ThemePickerCmp: React.FC<ThemePickerProps> = ({ dataHook, t }) => {
  const { themeList } = useThemeContext();

  return (
    <div data-hook={dataHook} className={s.container}>
      <div className={s.title}>{t('template.viewer.sidebar.defaultTheme')}</div>
      <div className={s.defaultTheme}>
        <ThemePickerButton dataHook={themePickerDataHooks.defaultTheme()} isDefaultTheme />
      </div>
      <div className={s.title}>{t('template.viewer.sidebar.featuredThemes')}</div>
      <div className={s.featuredThemes}>
        {themeList.map(({ id }) => (
          <ThemePickerButton key={id} themeId={id} dataHook={themePickerDataHooks.featuredTheme({ themeId: id })} />
        ))}
      </div>
    </div>
  );
};

export const ThemePicker = withTranslation()(ThemePickerCmp);
