import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import s from './desktopView.scss';
import { desktopViewDataHooks } from './DesktopView.dataHooks';
import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { useExperimentContext } from '../../../context/ExperimentContext/ExperimentContext';
import { useUIContext } from '../../../context/UIContext/UIContext';
import { useViewportSize } from '../../../hooks/useViewportSize';

interface Props {
  dataHook?: string;
  url: string;
  title: string;
}

const SIDEBAR_WIDTH = parseInt(s.sidebarWidth, 10);
const HEADER_HEIGHT = parseInt(s.headerHeight, 10);
const MIN_VIEW_WIDTH = 1200;

export const DesktopView: FC<Props> = ({ url, title, dataHook }) => {
  const iframe = useRef<HTMLIFrameElement>(null);
  const { bindTheme, setIframeBindings } = useThemeContext();
  const { activeExperiments } = useExperimentContext();
  const { isSidebarVisible } = useUIContext();
  const isNewViewerPage = activeExperiments.includes('NewViewerPageCapabilities');
  const isCollapsibleSidebar = activeExperiments.includes('TemplateViewerCollapsibleSidebar');
  const [scaledViewStyle, setScaledViewStyle] = useState<CSSProperties | null>(null);
  const { width: viewportWidth, height: viewportHeight } = useViewportSize();

  useEffect(() => {
    const currentIframe = iframe.current;

    if (isNewViewerPage) {
      const unbind = bindTheme(currentIframe);
      return unbind;
    }
  }, [url]);

  useEffect(() => {
    const isWideScreen = viewportWidth && viewportWidth >= MIN_VIEW_WIDTH + SIDEBAR_WIDTH;
    if (!isCollapsibleSidebar || !isSidebarVisible || isWideScreen || !viewportWidth || !viewportHeight) {
      setScaledViewStyle(null);
      return;
    }

    const widthWithoutSidebar = viewportWidth - SIDEBAR_WIDTH;
    const heightWithoutHeader = viewportHeight - HEADER_HEIGHT;
    const scale = widthWithoutSidebar / viewportWidth;

    setScaledViewStyle({
      transform: `scale(${widthWithoutSidebar / viewportWidth})`,
      width: widthWithoutSidebar / scale,
      height: heightWithoutHeader / scale,
    });
  }, [isCollapsibleSidebar, isSidebarVisible, viewportWidth, viewportHeight]);

  return (
    <div
      data-hook={dataHook}
      className={cs(s.display, {
        [s.sidebarVisible]: isSidebarVisible,
        [s.newViewerPage]: isNewViewerPage,
        [s.fullWidth]: isCollapsibleSidebar && (scaledViewStyle || !isSidebarVisible),
      })}
      style={scaledViewStyle ?? {}}
    >
      <iframe
        data-hook={desktopViewDataHooks.desktopIframe}
        ref={iframe}
        onLoad={() => {
          isNewViewerPage && setIframeBindings(iframe.current);
        }}
        src={url}
        title={title}
        width="100%"
        height="100%"
        className={s.iframe}
      />
    </div>
  );
};
