import { ThemeId } from './themeId';

export const THEME_KITS: Record<ThemeId, { title: string }> = {
  [ThemeId.DYNAMIC_THEME]: {
    title: 'template.viewer.theme.Dynamic',
  },
  [ThemeId.SAVVY_THEME]: {
    title: 'template.viewer.theme.Savvy',
  },
  [ThemeId.VIVID_THEME]: {
    title: 'template.viewer.theme.Vivid',
  },
  [ThemeId.MATURE_THEME]: {
    title: 'template.viewer.theme.Mature',
  },
  [ThemeId.CONTEMPORARY_THEME]: {
    title: 'template.viewer.theme.Contemporary',
  },
  [ThemeId.LAID_BACK_THEME]: {
    title: 'template.viewer.theme.Laid-back',
  },
  [ThemeId.BOHEMIAN_THEME]: {
    title: 'template.viewer.theme.Bohemian',
  },
  [ThemeId.FASHIONABLE_THEME]: {
    title: 'template.viewer.theme.Fashionable',
  },
  [ThemeId.TECHNICAL_THEME]: {
    title: 'template.viewer.theme.Technical',
  },
  [ThemeId.ENERGETIC_THEME]: {
    title: 'template.viewer.theme.Energetic',
  },
  [ThemeId.NOSTALGIC_THEME]: {
    title: 'template.viewer.theme.Nostalgic',
  },
  [ThemeId.NATURAL_THEME]: {
    title: 'template.viewer.theme.Natural',
  },
  [ThemeId.RETRO_VIBRANT_THEME]: {
    title: 'template.viewer.theme.RetroVibrant',
  },
  [ThemeId.WARM_THEME]: {
    title: 'template.viewer.theme.Warm',
  },
  [ThemeId.ECLECTIC_THEME]: {
    title: 'template.viewer.theme.Eclectic',
  },
  [ThemeId.CREAMY_THEME]: {
    title: 'template.viewer.theme.Creamy',
  },
  [ThemeId.TANGY_THEME]: {
    title: 'template.viewer.theme.Tangy',
  },
  [ThemeId.PLACID_THEME]: {
    title: 'template.viewer.theme.Placid',
  },
};
