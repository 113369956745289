export interface FontDescription {
  fontFamily: string;
  displayName: string;
  cdnName: string;
  fallbacks: string;
  genericFamily: string;
  provider: string;
  characterSets: string[];
  fontFaceDeclaration?: string;
}

export const fontsMetadata: Record<string, FontDescription> = {
  caudex: {
    displayName: 'Caudex',
    fontFamily: 'caudex',
    cdnName: 'Caudex',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
  },
  corben: {
    displayName: 'Corben',
    fontFamily: 'corben',
    cdnName: 'Corben',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin'],
    fallbacks: '',
  },
  forum: {
    displayName: 'Forum',
    fontFamily: 'forum',
    cdnName: 'Forum',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: '',
  },
  'din-next-w01-light': {
    displayName: 'DIN Next Light',
    fontFamily: 'din-next-w01-light',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: 'din-next-w02-light,din-next-w10-light',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "DIN-Next-W01-Light";
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix");
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3e0b2cd7-9657-438b-b4af-e04122e8f1f7.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/bc176270-17fa-4c78-a343-9fe52824e501.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/3516f91d-ac48-42cd-acfe-1be691152cc4.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d1b1e866-a411-42ba-8f75-72bf28e23694.svg#d1b1e866-a411-42ba-8f75-72bf28e23694") format("svg");
    }`,
  },
  'helvetica-w01-roman': {
    displayName: 'Helvetica',
    fontFamily: 'helvetica-w01-roman',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: 'helvetica-w02-roman,helvetica-lt-w10-roman',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "Helvetica-W01-Roman";
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ea95b44a-eab7-4bd1-861c-e73535e7f652.eot?#iefix");
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ea95b44a-eab7-4bd1-861c-e73535e7f652.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4021a3b9-f782-438b-aeb4-c008109a8b64.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/669f79ed-002c-4ff6-965c-9da453968504.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d17bc040-9e8b-4397-8356-8153f4a64edf.svg#d17bc040-9e8b-4397-8356-8153f4a64edf") format("svg");
    }`,
  },
  'helvetica-w01-bold': {
    displayName: 'Helvetica Bold',
    fontFamily: 'helvetica-w01-bold',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: 'helvetica-w02-bold,helvetica-lt-w10-bold',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "Helvetica-W01-Bold";
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f70da45a-a05c-490c-ad62-7db4894b012a.eot?#iefix");
      src: url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/f70da45a-a05c-490c-ad62-7db4894b012a.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c5749443-93da-4592-b794-42f28d62ef72.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/73805f15-38e4-4fb7-8a08-d56bf29b483b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/874bbc4a-0091-49f0-93ef-ea4e69c3cc7a.svg#874bbc4a-0091-49f0-93ef-ea4e69c3cc7a") format("svg");
    }`,
  },
  'helvetica neue': {
    displayName: 'Helvetica Neue',
    fontFamily: 'helvetica neue',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: '',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family: "Helvetica Neue";
      src: url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b7693a83-b861-4aa6-85e0-9ecf676bc4d6.eot?#iefix") format("embedded-opentype"),
      url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/bcf54343-d033-41ee-bbd7-2b77df3fe7ba.woff") format("woff"),
      url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/b0ffdcf0-26da-47fd-8485-20e4a40d4b7d.ttf") format("truetype"),
      url("//static.parastorage.com/services/third-party/fonts/Helvetica/Fonts/da09f1f1-062a-45af-86e1-2bbdb3dd94f9.svg#da09f1f1-062a-45af-86e1-2bbdb3dd94f9") format("svg");
  }`,
  },
  'futura-lt-w01-book': {
    displayName: 'Futura',
    fontFamily: 'futura-lt-w01-book',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: 'futura-lt-w05-book',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family:"Futura-LT-W01-Book";
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix");
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/cf053eae-ba1f-44f3-940c-a34b68ccbbdf.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/8bf38806-3423-4080-b38f-d08542f7e4ac.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/e2b9cbeb-fa8e-41cd-8a6a-46044b29ba52.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/c2a69697-4f06-4764-abd4-625031a84e31.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/dc423cc1-bf86-415c-bc7d-ad7dde416a34.svg#dc423cc1-bf86-415c-bc7d-ad7dde416a34") format("svg");
    }`,
  },
  'avenir-lt-w01_85-heavy1475544': {
    displayName: 'Avenir',
    fontFamily: 'avenir-lt-w01_85-heavy1475544',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: 'avenir-lt-w05_85-heavy',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family:"Avenir-LT-W01_85-Heavy1475544";
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix");
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/6af9989e-235b-4c75-8c08-a83bdaef3f66.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/d513e15e-8f35-4129-ad05-481815e52625.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/61bd362e-7162-46bd-b67e-28f366c4afbe.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/ccd17c6b-e7ed-4b73-b0d2-76712a4ef46b.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/20577853-40a7-4ada-a3fb-dd6e9392f401.svg#20577853-40a7-4ada-a3fb-dd6e9392f401") format("svg");
    }`,
  },
  'avenir-lt-w01_35-light1475496': {
    displayName: 'Avenir Light',
    fontFamily: 'avenir-lt-w01_35-light1475496',
    cdnName: '',
    genericFamily: 'sans-serif',
    provider: 'monotype',
    characterSets: ['latin'],
    fallbacks: 'avenir-lt-w05_35-light',
    fontFaceDeclaration: `
    @font-face {
      font-display: block;
      font-family:"Avenir-LT-W01_35-Light1475496";
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix");
      src:url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/edefe737-dc78-4aa3-ad03-3c6f908330ed.eot?#iefix") format("eot"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/0078f486-8e52-42c0-ad81-3c8d3d43f48e.woff2") format("woff2"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/908c4810-64db-4b46-bb8e-823eb41f68c0.woff") format("woff"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/4577388c-510f-4366-addb-8b663bcc762a.ttf") format("truetype"),url("//static.parastorage.com/services/third-party/fonts/user-site-fonts/fonts/b0268c31-e450-4159-bfea-e0d20e2b5c0c.svg#b0268c31-e450-4159-bfea-e0d20e2b5c0c") format("svg");
    }`,
  },
  'roboto-bold': {
    displayName: 'Roboto Bold',
    fontFamily: 'roboto-bold',
    cdnName: 'Roboto',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'roboto',
  },
  'poppins-extralight': {
    displayName: 'Poppins Extra Light',
    fontFamily: 'poppins-extralight',
    cdnName: 'Poppins',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'poppins',
  },
  'oswald-medium': {
    displayName: 'Oswald Medium',
    fontFamily: 'oswald-medium',
    cdnName: 'Oswald',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'oswald',
  },
  'cormorantgaramond-light': {
    displayName: 'Cormorant Garamond Light',
    fontFamily: 'cormorantgaramond-light',
    cdnName: 'Cormorant+Garamond',
    genericFamily: 'serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'cormorantgaramond,cormorant garamond',
  },
  'cormorantgaramond-semibold': {
    displayName: 'Cormorant Garamond Semi Bold',
    fontFamily: 'cormorantgaramond-semibold',
    cdnName: 'Cormorant+Garamond',
    genericFamily: 'serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'cormorantgaramond,cormorant garamond',
  },
  'lato-light': {
    displayName: 'Lato Light',
    fontFamily: 'lato-light',
    cdnName: 'Lato',
    genericFamily: 'sans-serif',
    provider: 'google-self-hosted',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: 'lato',
  },
  questrial: {
    displayName: 'Questrial',
    fontFamily: 'questrial',
    cdnName: 'Questrial',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin'],
    fallbacks: '',
  },
  montserrat: {
    displayName: 'Montserrat',
    fontFamily: 'montserrat',
    cdnName: 'Montserrat',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext', 'cyrillic'],
    fallbacks: '',
  },
  fahkwang: {
    displayName: 'Fahkwang',
    fontFamily: 'fahkwang',
    cdnName: 'Fahkwang',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
  },
  fraunces: {
    displayName: 'Fraunces',
    fontFamily: 'fraunces',
    cdnName: 'Fraunces',
    genericFamily: 'serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
  },
  syne: {
    displayName: 'Syne',
    fontFamily: 'syne',
    cdnName: 'Syne',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
  },
  'space grotesk': {
    displayName: 'Space Grotesk',
    fontFamily: 'space grotesk',
    cdnName: 'Space+Grotesk',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
  },
  quicksand: {
    displayName: 'Quicksand',
    fontFamily: 'quicksand',
    cdnName: 'Quicksand',
    genericFamily: 'sans-serif',
    provider: 'google',
    characterSets: ['latin', 'latin-ext'],
    fallbacks: '',
  },
};
