import React, { FC, useEffect } from 'react';
import { WithTranslation, withTranslation } from '@wix/wix-i18n-config';
import cs from 'classnames';
import s from './mobileApp.scss';
import * as BIManager from '../../utils/bi';
import { MobileToolbar } from '../partials/MobileToolbar/MobileToolbar';
import { MobileEditorXToolbar } from '../partials/MobileEditorXToolbar/MobileEditorXToolbar';
import { fedopsLogger } from '../../utils/fedops-logger';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useUIContext } from '../../context/UIContext/UIContext';
import { InfoButton } from '../InfoButton/InfoButton';
import { InfoPopUp } from '../InfoPopUp/InfoPopUp';
import { mobileAppDataHooks } from './MobileApp.dataHooks';

const MobileAppCmp: FC<{ dataHook?: string } & WithTranslation> = ({ t, dataHook }) => {
  const { isEditButtonHidden, isInfoShown } = useUIContext();
  const { template } = useTemplateContext();
  const { url, isResponsive, title } = template;
  const previewUrl = `${url}?showMobileView=true`;

  useEffect(() => {
    BIManager.reportLoadSuccess();
    if (template.isResponsive) {
      BIManager.reportResponsiveTemplateLoaded();
    }
    fedopsLogger.appLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div data-hook={dataHook}>
      {isResponsive ? (
        [
          <MobileEditorXToolbar
            template={template}
            key="toolbar"
            isEditButtonHidden={isEditButtonHidden}
            dataHook={mobileAppDataHooks.newMobileEditorxToolbar}
          />,
          <div className={s.goToBiggerScreen} key="bigger-screen" data-hook={mobileAppDataHooks.biggerScreen}>
            {t('template.viewer.goToBiggerScreen')}
          </div>,
        ]
      ) : (
        <MobileToolbar dataHook={mobileAppDataHooks.mobileToolBar}>
          <InfoButton dataHook={mobileAppDataHooks.infoView} />
        </MobileToolbar>
      )}
      <iframe
        data-hook={mobileAppDataHooks.mobileIframe}
        title={title}
        src={previewUrl}
        width="100%"
        height="100%"
        className={cs(s.iframe, { [s.iframeHidden]: isInfoShown })}
      />
      <InfoPopUp isMobile dataHook={mobileAppDataHooks.infoPopUp} />
      {isResponsive && (
        <div className={s.seeAllExpressions} data-hook={mobileAppDataHooks.seeAllExpressions}>
          <a href="https://www.wix.com/editorx/templates" className={s.seeAllExpressionsLink}>
            <span className={s.seeAllExpressionsLinkText}>{t('template.viewer.seeAllExpressions')}</span>
            &nbsp;→
          </a>
        </div>
      )}
    </div>
  );
};

export const MobileApp = withTranslation()(MobileAppCmp);
