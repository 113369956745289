export const rgbToHex = (value: string) =>
  '#' +
  value
    .split(',')
    .map((num) => {
      const hex = (+num).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    })
    .join('');

export const hexToRgb = (value: string) => {
  const hex = value.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r},${g},${b}`;
};
