export const enum ThemeId {
  DYNAMIC_THEME = 'DYNAMIC',
  SAVVY_THEME = 'SAVVY',
  VIVID_THEME = 'VIVID',
  MATURE_THEME = 'MATURE',
  CONTEMPORARY_THEME = 'CONTEMPORARY',
  LAID_BACK_THEME = 'LAID_BACK',
  BOHEMIAN_THEME = 'BOHEMIAN',
  FASHIONABLE_THEME = 'FASHIONABLE',
  TECHNICAL_THEME = 'TECHNICAL',
  ENERGETIC_THEME = 'ENERGETIC',
  NOSTALGIC_THEME = 'NOSTALGIC',
  NATURAL_THEME = 'NATURAL',
  RETRO_VIBRANT_THEME = 'RETRO_VIBRANT',
  WARM_THEME = 'WARM',
  ECLECTIC_THEME = 'ECLECTIC',
  CREAMY_THEME = 'CREAMY',
  TANGY_THEME = 'TANGY',
  PLACID_THEME = 'CALM',
}
