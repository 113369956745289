import React, { FC } from 'react';
import cs from 'classnames';
import { VIEW_MODE } from '../../utils/helpers';
import s from './templateDemo.scss';
import { templateDemoDataHook } from './TemplateDemo.dataHook';
import { DesktopView } from '../partials/DesktopView/DesktopView';
import { MobileView } from '../partials/MobileView/MobileView';
import { useTemplateContext } from '../../context/TemplateContext/TemplateContext';
import { useUIContext } from '../../context/UIContext/UIContext';
import { useExperimentContext } from '../../context/ExperimentContext/ExperimentContext';

export const TemplateDemo: FC<{ dataHook?: string }> = ({ dataHook }) => {
  const { viewMode, isInfoShown } = useUIContext();
  const { template } = useTemplateContext();
  const { activeExperiments } = useExperimentContext();
  const { url, title } = template;

  const isNewViewerPageCapabilities = activeExperiments.includes('NewViewerPageCapabilities');

  return (
    <div
      data-hook={dataHook}
      className={cs(s.demoDisplay, { [s.disabled]: isInfoShown, [s.newViewerPage]: isNewViewerPageCapabilities })}
    >
      {viewMode === VIEW_MODE.DESKTOP ? (
        <DesktopView url={url} title={title} dataHook={templateDemoDataHook.desktopView} />
      ) : (
        <MobileView url={url} title={title} dataHook={templateDemoDataHook.mobileView} />
      )}
    </div>
  );
};
