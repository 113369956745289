/* Adapted from https://github.com/wix-private/santa-editor/blob/master/santa-editor/packages/newDesignPanel/Theme/constants/palettes.ts */
import { ThemeId } from './themeId';

export const COLORS: Record<string, { [key: string]: string }> = {
  [ThemeId.DYNAMIC_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#F3A2DE',
    color_17: '#E773C8',
    color_18: '#DB00A1',
    color_19: '#92006B',
    color_20: '#490036',
    color_21: '#F1FEC4',
    color_22: '#EAFCA7',
    color_23: '#D6FB51',
    color_24: '#8FA736',
    color_25: '#47541B',
    color_26: '#B5BCF0',
    color_27: '#8F98E2',
    color_28: '#384AD3',
    color_29: '#25318D',
    color_30: '#131946',
    color_31: '#D7E8B1',
    color_32: '#BCD28F',
    color_33: '#72A407',
    color_34: '#5C8505',
    color_35: '#395203',
  },
  [ThemeId.SAVVY_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#200174',
    color_12: '#4A2F96',
    color_13: '#654BAD',
    color_14: '#AF9DE0',
    color_15: '#FFFFFF',
    color_16: '#3F1A40',
    color_17: '#7E3480',
    color_18: '#FB68FF',
    color_19: '#FC96FF',
    color_20: '#FDCCFF',
    color_21: '#435511',
    color_22: '#87AA22',
    color_23: '#CAFF33',
    color_24: '#E4FF99',
    color_25: '#EDFFBB',
    color_26: '#553218',
    color_27: '#AA6330',
    color_28: '#FF9548',
    color_29: '#FFCAA3',
    color_30: '#FFDCC2',
    color_31: '#2E3F39',
    color_32: '#5C7D71',
    color_33: '#B7FAE2',
    color_34: '#CAFAE9',
    color_35: '#E8FCF5',
  },
  [ThemeId.VIVID_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#E8AAA9',
    color_17: '#D27D7C',
    color_18: '#BB2423',
    color_19: '#7D1817',
    color_20: '#3E0C0C',
    color_21: '#FFC4E4',
    color_22: '#FEA6D7',
    color_23: '#FE4DAF',
    color_24: '#A93375',
    color_25: '#551A3A',
    color_26: '#D1C0E2',
    color_27: '#AF98C4',
    color_28: '#825CA7',
    color_29: '#573D6F',
    color_30: '#2B1F38',
    color_31: '#FEE4B3',
    color_32: '#FCD68C',
    color_33: '#FBAF1C',
    color_34: '#A77513',
    color_35: '#543A09',
  },
  [ThemeId.MATURE_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#D2B4D1',
    color_17: '#A682A4',
    color_18: '#794577',
    color_19: '#512E4F',
    color_20: '#281728',
    color_21: '#FEE6C4',
    color_22: '#FCDAA7',
    color_23: '#FBB651',
    color_24: '#A77936',
    color_25: '#543D1B',
    color_26: '#D2D2B4',
    color_27: '#A5A682',
    color_28: '#787945',
    color_29: '#50512E',
    color_30: '#282817',
    color_31: '#BDD5CF',
    color_32: '#91ABA5',
    color_33: '#30574E',
    color_34: '#24423B',
    color_35: '#0E1A17',
  },
  [ThemeId.CONTEMPORARY_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#D6BCBA',
    color_17: '#AD918F',
    color_18: '#5B2F2C',
    color_19: '#381D1B',
    color_20: '#120909',
    color_21: '#EDE5DD',
    color_22: '#E8D6C1',
    color_23: '#CDAF8C',
    color_24: '#7D6B55',
    color_25: '#54493A',
    color_26: '#FEFFAE',
    color_27: '#FEFF86',
    color_28: '#FCFF0D',
    color_29: '#A8AA09',
    color_30: '#545504',
    color_31: '#FCFCFA',
    color_32: '#FAF8F2',
    color_33: '#F5F3E9',
    color_34: '#C4C2BA',
    color_35: '#93928C',
  },
  [ThemeId.LAID_BACK_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#4A9183',
    color_17: '#35685E',
    color_18: '#014235',
    color_19: '#1D2927',
    color_20: '#000000',
    color_21: '#DCE3F5',
    color_22: '#C0CBED',
    color_23: '#97ADED',
    color_24: '#4B5676',
    color_25: '#262B3B',
    color_26: '#E1C1BC',
    color_27: '#C29992',
    color_28: '#A45F53',
    color_29: '#6D3F37',
    color_30: '#37201C',
    color_31: '#FFFDF7',
    color_32: '#FAF5EB',
    color_33: '#F4EBD7',
    color_34: '#C3BCAC',
    color_35: '#7A766C',
  },
  [ThemeId.BOHEMIAN_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#C5CEBF',
    color_17: '#939E8C',
    color_18: '#5E6D55',
    color_19: '#3F4939',
    color_20: '#1F241C',
    color_21: '#ECDFF5',
    color_22: '#D9B7ED',
    color_23: '#C786ED',
    color_24: '#644376',
    color_25: '#32213B',
    color_26: '#FDFFF0',
    color_27: '#F8FFC7',
    color_28: '#EEFF70',
    color_29: '#778038',
    color_30: '#3C401C',
    color_31: '#F7F1E9',
    color_32: '#F0E5D5',
    color_33: '#EEDEC9',
    color_34: '#BEB2A1',
    color_35: '#8F8579',
  },
  [ThemeId.FASHIONABLE_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#F0A0A4',
    color_17: '#E07076',
    color_18: '#D1000A',
    color_19: '#8B0007',
    color_20: '#460003',
    color_21: '#FFF0F5',
    color_22: '#FFD4E1',
    color_23: '#FFADC7',
    color_24: '#A36F80',
    color_25: '#664450',
    color_26: '#C7A191',
    color_27: '#967569',
    color_28: '#7C452F',
    color_29: '#542F20',
    color_30: '#170D09',
    color_31: '#E4EEF2',
    color_32: '#D5E7F0',
    color_33: '#B9D7E5',
    color_34: '#95AEBA',
    color_35: '#677880',
  },
  [ThemeId.TECHNICAL_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#F6F6F6',
    color_12: '#C5C5C5',
    color_13: '#949494',
    color_14: '#626262',
    color_15: '#281A39',
    color_16: '#B8A7E7',
    color_17: '#907ACF',
    color_18: '#4720B7',
    color_19: '#2F157A',
    color_20: '#180B3D',
    color_21: '#BDE9FB',
    color_22: '#9CDDF6',
    color_23: '#40C0F2',
    color_24: '#2B80A1',
    color_25: '#154051',
    color_26: '#D0F7E0',
    color_27: '#AFF0CA',
    color_28: '#70F0A6',
    color_29: '#387853',
    color_30: '#1C3C29',
    color_31: '#FEFFC1',
    color_32: '#FDFFA3',
    color_33: '#FBFF46',
    color_34: '#A7AA2F',
    color_35: '#545517',
  },
  [ThemeId.ENERGETIC_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#AFACFB',
    color_17: '#8782F6',
    color_18: '#170DF2',
    color_19: '#0F09A1',
    color_20: '#080451',
    color_21: '#FFEBFF',
    color_22: '#FFD6FF',
    color_23: '#FFADFF',
    color_24: '#805780',
    color_25: '#402B40',
    color_26: '#FEF2AE',
    color_27: '#FEEB85',
    color_28: '#FDD80D',
    color_29: '#A99009',
    color_30: '#544804',
    color_31: '#FEC4B2',
    color_32: '#FDA68C',
    color_33: '#FC4E1A',
    color_34: '#A83411',
    color_35: '#541A09',
  },
  [ThemeId.NOSTALGIC_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FFFFFF',
    color_12: '#E8E6E6',
    color_13: '#C7C7C7',
    color_14: '#757575',
    color_15: '#000000',
    color_16: '#DFA7B6',
    color_17: '#BF778A',
    color_18: '#9F2747',
    color_19: '#6A1A2F',
    color_20: '#350D18',
    color_21: '#FFF0EB',
    color_22: '#FFE0D4',
    color_23: '#F8D1C2',
    color_24: '#957D74',
    color_25: '#6B5A54',
    color_26: '#E3E0C0',
    color_27: '#C6C398',
    color_28: '#AAA55B',
    color_29: '#716E3D',
    color_30: '#39371E',
    color_31: '#BCC8D9',
    color_32: '#8F9DB3',
    color_33: '#556B8D',
    color_34: '#39475E',
    color_35: '#1C242F',
  },
  [ThemeId.NATURAL_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#FAECE1',
    color_12: '#C8BDB4',
    color_13: '#968E87',
    color_14: '#645E5A',
    color_15: '#1D0E03',
    color_16: '#A3D9CF',
    color_17: '#78B3A9',
    color_18: '#016855',
    color_19: '#015243',
    color_20: '#00342B',
    color_21: '#B1EBD6',
    color_22: '#90D7BD',
    color_23: '#02AE70',
    color_24: '#018253',
    color_25: '#015738',
    color_26: '#FAC6B5',
    color_27: '#F5A98F',
    color_28: '#F05B29',
    color_29: '#A03D1B',
    color_30: '#501E0E',
    color_31: '#DDBEA6',
    color_32: '#BB987D',
    color_33: '#773400',
    color_34: '#542400',
    color_35: '#240F00',
  },
  [ThemeId.RETRO_VIBRANT_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#F6F9F5',
    color_12: '#C5C7C4',
    color_13: '#949593',
    color_14: '#626462',
    color_15: '#091405',
    color_16: '#7DAAB9',
    color_17: '#4D8495',
    color_18: '#035772',
    color_19: '#034459',
    color_20: '#011014',
    color_21: '#F5FEB8',
    color_22: '#EFFE95',
    color_23: '#E0FD2C',
    color_24: '#95A91D',
    color_25: '#4B540F',
    color_26: '#FAE1E3',
    color_27: '#F0CED1',
    color_28: '#F6A0A9',
    color_29: '#B3747B',
    color_30: '#5C3C3E',
    color_31: '#E0A7C5',
    color_32: '#C0779E',
    color_33: '#A12767',
    color_34: '#6B1A45',
    color_35: '#360D22',
  },
  [ThemeId.WARM_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#F8F4F1',
    color_12: '#C6C3C1',
    color_13: '#959291',
    color_14: '#636260',
    color_15: '#301D10',
    color_16: '#E0B8A5',
    color_17: '#C28D74',
    color_18: '#A34A21',
    color_19: '#6D3116',
    color_20: '#36190B',
    color_21: '#F3EBF5',
    color_22: '#EDDDF0',
    color_23: '#E6C7EB',
    color_24: '#B89FBC',
    color_25: '#847387',
    color_26: '#FAD8C5',
    color_27: '#F7B797',
    color_28: '#F7925E',
    color_29: '#7C492F',
    color_30: '#3E2518',
    color_31: '#F2E5E4',
    color_32: '#F2D9D8',
    color_33: '#F3C7C4',
    color_34: '#C29F9D',
    color_35: '#927776',
  },
  [ThemeId.ECLECTIC_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#000000',
    color_12: '#3B3B3B',
    color_13: '#666666',
    color_14: '#D1D1D1',
    color_15: '#FFFFFF',
    color_16: '#2E282F',
    color_17: '#78687A',
    color_18: '#E6C7EB',
    color_19: '#EED3F2',
    color_20: '#F0E4F2',
    color_21: '#4F1D0E',
    color_22: '#9E3B1B',
    color_23: '#FE602F',
    color_24: '#F3A78F',
    color_25: '#F9C5B4',
    color_26: '#524217',
    color_27: '#A3842E',
    color_28: '#F5C645',
    color_29: '#F8E19F',
    color_30: '#FCECBF',
    color_31: '#15291B',
    color_32: '#2B5336',
    color_33: '#64CEBB',
    color_34: '#7FA88B',
    color_35: '#B1D3BB',
  },
  [ThemeId.CREAMY_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#063F0D',
    color_12: '#38653D',
    color_13: '#628C67',
    color_14: '#8AC28F',
    color_15: '#F5FFF7',
    color_16: '#9E7833',
    color_17: '#D6B578',
    color_18: '#FDEBC9',
    color_19: '#F7EFDF',
    color_20: '#FCF8F0',
    color_21: '#4F1D0E',
    color_22: '#9E3B1B',
    color_23: '#ED6C5C',
    color_24: '#F3A78F',
    color_25: '#F9C5B4',
    color_26: '#314532',
    color_27: '#36753A',
    color_28: '#72A075',
    color_29: '#A1C4A3',
    color_30: '#C6CFC7',
    color_31: '#19232A',
    color_32: '#324554',
    color_33: '#4B687E',
    color_34: '#879AA9',
    color_35: '#B7C8D4',
  },
  [ThemeId.TANGY_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#000000',
    color_12: '#525252',
    color_13: '#666666',
    color_14: '#999999',
    color_15: '#FFFFFF',
    color_16: '#224916',
    color_17: '#44912D',
    color_18: '#66DA43',
    color_19: '#A9E697',
    color_20: '#C8F3BB',
    color_21: '#47541B',
    color_22: '#8FA736',
    color_23: '#D6FB51',
    color_24: '#EAFCA7',
    color_25: '#F1FEC4',
    color_26: '#4E1450',
    color_27: '#9C299F',
    color_28: '#EA3DEF',
    color_29: '#F299F4',
    color_30: '#F8BCFA',
    color_31: '#4E1118',
    color_32: '#9D222F',
    color_33: '#EB3347',
    color_34: '#F2939D',
    color_35: '#F8B8BF',
  },
  [ThemeId.PLACID_THEME]: {
    color_0: '#FFFFFF',
    color_1: '#FFFFFF',
    color_2: '#000000',
    color_3: '#ED1C24',
    color_4: '#0088CB',
    color_5: '#FFCB05',
    color_6: '#727272',
    color_7: '#B0B0B0',
    color_8: '#FFFFFF',
    color_9: '#727272',
    color_10: '#B0B0B0',
    color_11: '#393D32',
    color_12: '#6A6E64',
    color_13: '#989E90',
    color_14: '#C8C9C5',
    color_15: '#F1F3EE',
    color_16: '#635D53',
    color_17: '#9E9683',
    color_18: '#F1E3C8',
    color_19: '#F0E9DA',
    color_20: '#F2F1F0',
    color_21: '#4F1D0E',
    color_22: '#852A0C',
    color_23: '#AA3D1D',
    color_24: '#F3A78F',
    color_26: '#2C2935',
    color_27: '#58536A',
    color_25: '#F9C5B4',
    color_28: '#B1A5D3',
    color_29: '#C9BBF0',
    color_30: '#D6D0E9',
    color_31: '#2E3023',
    color_32: '#5C6146',
    color_33: '#B7C18C',
    color_34: '#DDE6B5',
    color_35: '#EEF0E9',
  },
};
