import React, { PropsWithChildren, useEffect } from 'react';
import { getTheme, THEMES } from './constants';
import { defaultTheme, Theme, ThemeContext, ThemeContextShape, DefaultTheme } from './ThemeContext';
import { hexToRgb, rgbToHex } from '../../utils/colorConverters';
import { ThemeId } from './constants/themeId';
import { loadMainFontFromInitialTheme, loadThemeFonts } from './themeAPI';

interface ThemeContextProviderProps {}

const findRootStyleRule = (documentStylesheets: StyleSheetList) =>
  Array.from(documentStylesheets)
    .flatMap((sheet) => {
      let rules;
      try {
        rules = sheet.cssRules;
      } catch (e) {}
      return Array.from(rules ?? []);
    })
    .find((rule) => (rule as CSSStyleRule).cssText.includes('--color_1:'));

const getThemeColorsAndFonts = (cssStyleProperties: CSSStyleDeclaration) => {
  return Array.from(cssStyleProperties)
    .filter((property) => property.startsWith('--color_') || property.startsWith('--font_'))
    .reduce(
      (acc: Pick<Theme, 'colors' | 'fonts'>, key) => {
        const themeKey = key.replace(/^--/, '');
        const value = cssStyleProperties.getPropertyValue(key);
        if (key.includes('--color_')) {
          acc.colors[themeKey] = rgbToHex(value);
        }
        if (key.includes('--font_')) {
          acc.fonts[themeKey] = value;
        }

        return acc;
      },
      { colors: {}, fonts: {} },
    );
};

export const ThemeContextProvider: React.FC<PropsWithChildren<ThemeContextProviderProps>> = ({ children }) => {
  const cssStylePropertiesRef = React.useRef<CSSStyleDeclaration | null>(null);
  const iframeDocumentRef = React.useRef<Document | null>(null);

  const [currentThemeId, setCurrentThemeId] = React.useState<ThemeId | null>(defaultTheme.id);
  const [initialTheme, setInitialTheme] = React.useState<DefaultTheme>(defaultTheme);

  const setCurrentCssStyleProperties = (theme: Theme | DefaultTheme) => {
    if (!cssStylePropertiesRef.current || !iframeDocumentRef.current) {
      return;
    }

    loadThemeFonts(theme, iframeDocumentRef.current, cssStylePropertiesRef.current);

    Object.entries(theme.colors).forEach(([colorName, colorValue]) => {
      cssStylePropertiesRef.current?.setProperty(`--${colorName}`, hexToRgb(colorValue));
    });
  };

  const setIframeBindings = (iframe: HTMLIFrameElement | null) => {
    const { document } = iframe?.contentWindow ?? {};
    if (!document) {
      return;
    }
    iframeDocumentRef.current = document;
    const rootStyleRule = findRootStyleRule(document.styleSheets);
    if (!rootStyleRule) {
      return;
    }

    // @ts-expect-error
    rootStyleRule.selectorText = ':root, :root *';
    // @ts-expect-error
    cssStylePropertiesRef.current = rootStyleRule.style;
  };

  const bindTheme = (iframe: HTMLIFrameElement | null) => {
    const handleThemeBind = () => {
      setIframeBindings(iframe);
      if (!cssStylePropertiesRef.current || !iframeDocumentRef.current) {
        return;
      }

      const theme = getThemeColorsAndFonts(cssStylePropertiesRef.current);
      loadMainFontFromInitialTheme(theme, iframeDocumentRef.current);
      setInitialTheme({ ...defaultTheme, ...theme });
    };

    iframe?.contentWindow?.addEventListener('DOMContentLoaded', handleThemeBind);

    return () => {
      iframe?.contentWindow?.removeEventListener('DOMContentLoaded', handleThemeBind);
    };
  };

  useEffect(() => {
    const theme = getTheme(currentThemeId) ?? initialTheme;
    setCurrentCssStyleProperties(theme);
  }, [currentThemeId, initialTheme]);

  const themeContextValue: ThemeContextShape = React.useMemo(
    () => ({
      bindTheme,
      setIframeBindings,
      initialTheme,
      currentThemeId,
      setTheme: setCurrentThemeId,
      themeList: THEMES,
    }),
    [currentThemeId, initialTheme],
  );

  return <ThemeContext.Provider value={themeContextValue}>{children}</ThemeContext.Provider>;
};
