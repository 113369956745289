/* Adapted from https://github.com/wix-private/santa-editor/blob/master/santa-editor/packages/newDesignPanel/Theme/constants/themes.ts */
import { ThemeId } from './themeId';
import { COLORS } from './colors';
import { FONTS } from './fonts';
import { THEME_KITS } from './translations';
import { Theme } from '../ThemeContext';

export const THEMES_ORDER = [
  ThemeId.ENERGETIC_THEME,
  ThemeId.RETRO_VIBRANT_THEME,
  ThemeId.ECLECTIC_THEME,
  ThemeId.DYNAMIC_THEME,
  ThemeId.TECHNICAL_THEME,
  ThemeId.NOSTALGIC_THEME,
  ThemeId.NATURAL_THEME,
  ThemeId.SAVVY_THEME,
  ThemeId.CONTEMPORARY_THEME,
  ThemeId.WARM_THEME,
  ThemeId.PLACID_THEME,
  ThemeId.FASHIONABLE_THEME,
  ThemeId.BOHEMIAN_THEME,
  ThemeId.MATURE_THEME,
  ThemeId.TANGY_THEME,
  ThemeId.VIVID_THEME,
  ThemeId.CREAMY_THEME,
  ThemeId.LAID_BACK_THEME,
];

export const THEMES: Theme[] = THEMES_ORDER.map((id: ThemeId) => ({
  id,
  colors: COLORS[id],
  fonts: FONTS[id],
  ...THEME_KITS[id],
}));

export const getTheme = (themeId: string | null): Theme | null => {
  const theme = THEMES.find((item) => item.id === themeId);

  if (!theme) {
    return null;
  }

  return theme;
};
