import React from 'react';
import cs from 'classnames';
import { SidebarHeader } from '../SidebarHeader/SidebarHeader';
import { ThemePicker } from '../ThemePicker/ThemePicker';
import s from './Sidebar.scss';
import { useUIContext } from '../../context/UIContext/UIContext';

interface SidebarProps {
  dataHook?: string;
}

export const Sidebar: React.FC<SidebarProps> = ({ dataHook }) => {
  const { isSidebarVisible } = useUIContext();

  return (
    <>
      <div className={cs(s.sidebarShadow, { [s.visible]: isSidebarVisible })} />
      <div className={cs(s.sidebar, { [s.visible]: isSidebarVisible })} data-hook={dataHook}>
        <SidebarHeader />
        <ThemePicker />
      </div>
    </>
  );
};
