import React, { FC, useEffect, useRef } from 'react';
import cs from 'classnames';
import s from './mobileView.scss';
import { mobileViewDataHooks } from './MobileView.dataHooks';
import { useUIContext } from '../../../context/UIContext/UIContext';
import { useThemeContext } from '../../../context/ThemeContext/ThemeContext';
import { useExperimentContext } from '../../../context/ExperimentContext/ExperimentContext';

interface Props {
  dataHook?: string;
  url: string;
  title: string;
}

export const MobileView: FC<Props> = ({ url, title, dataHook }) => {
  const previewUrl = `${url}?showMobileView=true`;
  const iframe = useRef<HTMLIFrameElement>(null);
  const { isSidebarVisible } = useUIContext();
  const { bindTheme, setIframeBindings } = useThemeContext();
  const { activeExperiments } = useExperimentContext();
  const isNewViewerPage = activeExperiments.includes('NewViewerPageCapabilities');

  useEffect(() => {
    const currentIframe = iframe.current;

    if (isNewViewerPage) {
      const unbind = bindTheme(currentIframe);
      return unbind;
    }
  }, [previewUrl]);

  return (
    <div data-hook={dataHook} className={s.display}>
      <div className={cs(s.phone, { [s.sidebarVisible]: isSidebarVisible })}>
        <div className={s.speaker} />
        <div className={`${s.display} ${s.device}`}>
          <iframe
            ref={iframe}
            src={previewUrl}
            onLoad={() => {
              isNewViewerPage && setIframeBindings(iframe.current);
            }}
            title={title}
            width="100%"
            height="100%"
            data-hook={mobileViewDataHooks.mobileIframe}
          />
        </div>
      </div>
    </div>
  );
};
