import React, { FC, PropsWithChildren } from 'react';

import { VIEW_MODE } from '../utils/helpers';
import { UIContextProvider } from './UIContext/UIContextProvider';
import { TemplateContextProvider } from './TemplateContext/TemplateContextProvider';
import { StoreTemplate } from './TemplateContext/TemplateContext';
import { ConfigContextProvider } from './ConfigContext/ConfigContextProvider';
import { ConfigContextShape } from './ConfigContext/ConfigContext';
import { ExperimentContextProvider } from './ExperimentContext/ExperimentContextProvider';

export interface StoreInitialState {
  viewMode: VIEW_MODE;
  isInfoShown: boolean;
  isEditButtonHidden: boolean;
  template: StoreTemplate;
  config: ConfigContextShape;
  activeExperiments: string[];
}

interface ContextProviderProps {
  initialState: StoreInitialState;
}

export const ContextProvider: FC<PropsWithChildren<ContextProviderProps>> = ({ initialState, children }) => {
  const uiContextProviderInitialState = {
    viewMode: initialState.viewMode,
    isEditButtonHidden: initialState.isEditButtonHidden,
    isInfoShown: initialState.isEditButtonHidden,
  };

  const templateContextProviderInitialState = {
    template: initialState.template,
  };

  const experimentContextProviderInitialState = {
    activeExperiments: initialState.activeExperiments,
  };

  const configContextProviderInitialState = {
    config: initialState.config,
  };

  return (
    <TemplateContextProvider initialState={templateContextProviderInitialState}>
      <ExperimentContextProvider initialState={experimentContextProviderInitialState}>
        <UIContextProvider initialState={uiContextProviderInitialState}>
          <ConfigContextProvider initialState={configContextProviderInitialState}>{children}</ConfigContextProvider>
        </UIContextProvider>
      </ExperimentContextProvider>
    </TemplateContextProvider>
  );
};
